<template>

</template>

<script>
export default {
    name: "Language",
    methods: {
        whatLanguageShow(ru,en) {
            return this.$i18n.locale === 'ru' ? ru : this.$i18n.locale === 'en' ? en : '';
        },
    },
}
</script>

<style scoped>

</style>
